import * as React from "react"

import Seo from "../components/seo"

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import logo from "../images/logo.svg";
import pomPomBagelsLogo from "../images/pom-pom-bagels-logo.svg";

library.add(fas, fab)

// styles
const logoStyles = {
  maxWidth: 400,
  width: '100%',
  height: 'auto',
}


// markup
const IndexPage = () => {
  return (
    <main>
      <Seo title="The best burgers in Lagos, Portugal" description="When it comes to delicious bites in Lagos Portugal, illicit Burgers specialises in smash burgers that make time stand still." />
      
      <section className="header bg-darkGrey grid lg:grid-cols-2">
        <div className="relative col-span-1 flex justify-center p-12">
          <div className="header-bg hidden lg:block"></div>
          <img src={logo} className="h-auto" alt="illicit Burgers" width="400" height="400" style={logoStyles} />
        </div>
        <div className="col-span-1 px-4 pb-12 lg:p-16">
          <h1 className="text-center">
            illicit Burgers
          </h1>
          <div className="text-center">
            <p>When it comes to delicious bites in Lagos Portugal, illicit Burgers specialises in smash burgers that make time stand still. If you haven't heard already, this speciality burger joint is a must try while in Lagos.</p>
          </div>
          <div className="flex flex-wrap justify-center">
            <a href={'/illicit-burgers-menu-english.pdf'} className="button w-full md:w-auto" aria-label="View our menu here in PDF format (EN)" target="_blank" rel="noopener noreferrer">View our menu (EN)</a>
            <a href={'/illicit-burgers-menu-portuguese.pdf'} className="button w-full md:w-auto" aria-label="Veja o nosso menu em PDF (PT)" target="_blank" rel="noopener noreferrer">Veja o nosso menu (PT)</a>
            <p class="w-full text-center mt-8 mb-4">Want our meat in your mouth now?</p>
            <a href={'https://www.ubereats.com/pt-en/store/illicit-burgers/LmZPe5a5S2amlx7YoRRnHg'} className="button button-solid w-full md:w-auto" aria-label="Click here for delivery" target="_blank" rel="noopener noreferrer">Click here for delivery</a>
          </div>
          <hr />
          <div className="text-center mt-8">
            <h2 className="flex justify-center items-center">Find us at <FontAwesomeIcon icon={['fas', 'map-marked-alt']} size="xs" className="ml-4" /></h2>
            <p>
              <a
                href="https://www.google.com/maps/place/illicit+Burgers/@37.1001265,-8.6728612,15z/data=!4m2!3m1!1s0x0:0x56bfaebedb833cf0?sa=X&ved=2ahUKEwjc78z3lufuAhXjRRUIHYaHDUoQ_BIwCnoECBEQBQ"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="View our location on Google Maps">
                R. Prof. Luis de Azevedo 1<br />
                8600-583 Lagos<br />
                Portugal
              </a>
            </p>
            <p>
              Email: <a href="mailto:admin@illicitburgers.com" rel="noopener">admin@illicitburgers.com</a><br />
              Tel: <a href="tel:(+351) 927 715 052" rel="noopener">(+351) 927 715 052</a>
            </p>
            <p>
              <a
                href="https://facebook.com/Illicitburgers/"
                className="mx-2 text-lg"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Go to illicit Burgers Facebook">
                <FontAwesomeIcon icon={['fab', 'facebook']} />
              </a>
              <a
                href="https://www.instagram.com/illicitburgers/"
                className="mx-2 text-lg"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Go to illicit Burgers Instagram">
                <FontAwesomeIcon icon={['fab', 'instagram']} />
              </a>
            </p>
          </div>
          <hr />
          <div className="flex flex-wrap justify-center items-center lg:mt-8">
            <img src={pomPomBagelsLogo} className="mb-4 max-w-full w-32 h-auto" width="160" height="154" alt="Pom Pom Bagels" />
            <div className="px-4 lg:px-8 text-center max-w-full w-42 md:w-80">
              <p className="text-xs mb-4">We also have a sister cafe selling delicious homemade bagels with various fillings.</p>
              <p className="text-xs mb-4">Open 8am until 3pm.</p>
              <p className="mb-0">
                <a
                  href="https://pompombagels.com"
                  className="text-xs"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Go to our sister cafe's website: pompombagels.com">pompombagels.com</a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-black grid lg:grid-cols-2 py-8">
        <div className="col-span-1 flex justify-center px-6 lg:p-16">
          <div>
            <h2>What is a smash burger?</h2>
            <p>For those who have never tried one – we're sorry to hear that. Quick history lesson: the smash burger is actually the OG of the hamburger world. Dating back to 1902, it was the inspiration for the modern cheeseburger we know and love today, and it's making a comeback. Bigtime.</p>
            <p>What makes it different? Well, it's pressed against the grill with a cast iron weight until a meaty crust is formed that traps all those sweet sweet burger juices. There's only one way to really find out.</p>
            <p>And speaking of patties, we use 100% grass fed beef (an 80/20 chuck mix) from a highly reputable local butcher right here in Lagos. If you don't eat meat, fear not! Our vegetarian and vegan beyond burgers are just as amazing.</p>
          </div>
        </div>

        <div className="col-span-1 flex justify-center px-6 lg:p-16">
          <div>
            <h3>We pride ourselves on our mouth-watering takeaway burgers</h3>
            <p>Of course, you're more than welcome to soak up the good music, friendly vibes and laid-back atmosphere of Illicit Burgers in person! However, please be aware that seating is limited in our intimate space.</p>
            <p>Whether you're a passionate burger-lover or one of those weirdos who puts ketchup on pineapple, Illicit Burgers is a safe space. You might be peckish after a surf, ravenous after a hike, or hungover after a night of partying.</p>
            <p>Browse our menu, place your order via UberEats and relax... you're about to enjoy the finest burger that Lagos Portugal has to offer, delivered right to your door faster than you can say "I should've ordered sides".</p>
            <p className="mb-0">Oh yeah, don't skimp on our sides by the way – our sides are the talk of the town.</p>
          </div>
        </div>
      </section>

      <section className="bg-darkGrey grid lg:grid-cols-2 py-8">
        <div className="col-span-1 flex justify-center px-6 lg:p-16">
          <div>
            <h3>Our Story</h3>
            <p>I know what you're thinking. What the hell does a burger that's forbidden by law, rules, or custom taste like? We'll tell you what it tastes like...</p>
            <p>It tastes like Mac and cheese fritters instead of a bun. It tastes like chicken, beef, pork and bacon all crammed into the same burger or even a burger that comes accompanied with its own cheese bath, just because.</p>
            <p>Come back every week for a new, special, more outrageous burger that's trying to outdo its predecessor.</p>
          </div>
        </div>
        
        <div className="col-span-1 flex justify-center px-6 lg:p-16">
          <div>
            <p>Don't worry – your parents never have to know what kind of bizarre and off-beat burgers you've been smashing at Illicit Burgers. Your secret is safe with us.</p>
            <p>Let's get weird. We crazy up in here.</p>
            <p>We must warn you though – for the rest of your life, you'll never be able to devour another ordinary “plain Jane” burger without thinking about our Lagos Portugal burgers the entire time.</p>
            <p className="mb-0">From tender beef patties and chicken fillets to unforgettable signature sauces and more-ish sides; you'll never be the same again. </p>
          </div>
        </div>
      </section>

      <section className="bg-black flex justify-center p-6">
        <p className="mb-0">View our <a href="/privacy-policy">Privacy Policy</a>.</p>
      </section>
    </main>
  )
}

export default IndexPage
